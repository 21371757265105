<template>
  <div class="main-section section" :style="{ backgroundImage: backgroundUrl }">
    <h4 class="title is-4">Pregunta {{ currentQuestion + 1 }}</h4>
    <h3 class="title is-3">{{ questions[currentQuestion].title }}</h3>
    <div v-if="filteredOptions.length"  class="results">
      <Bar :data="chartData" :options="chartOptions"/>
    </div>
    <div v-else>
        <h1 class="title is-1">Entra para votar</h1>
      </div>
  </div>
</template>

<script>
import "./screenLivePoll.scss";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  components: {
    Bar
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentQuestion: 0,
      votes: [],
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 16 / 9,
        indexAxis: 'x',
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1
            }
          },
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              font: {
                  family: 'Arial',
                  size: 14,
                  weight: 'bold', 
              },
              color: 'rgb(54, 54, 54)' 
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            labels: {
                font: {
                    size: 14
                }
            }
          }
        }
      },
      colors: {
        border: 'rgba(75, 192, 192, .6)',
        background: 'rgba(75, 192, 192, 1)',
      }
    };
  },
  sockets: {
    playerJoined(player) {
      console.log(player);
      const existingVote = this.votes.find(vote => vote.dbid === player.dbid);
      
      const currentState = {
        currentQuestion: this.currentQuestion,
        existingVote: existingVote ? this.questions[this.currentQuestion].options[existingVote.optionIndex].id : null
      };

      this.$socket.client.emit("messageToPlayer", {
          to: player.socketid,
          msg: "currentState",
          data: currentState
        });
    },
    vote(data) {
      console.log(data);
      const optionIndex = this.questions[this.currentQuestion].options.findIndex(option => option.id === data.voteId);

      if (optionIndex !== -1) {
        // Check if the user has already voted
        const existingVoteIndex = this.votes.findIndex(vote => vote.dbid === data.dbid);
        
        if (existingVoteIndex !== -1) {
          // If the user has already voted, update their vote
          this.$set(this.votes, existingVoteIndex, { dbid: data.dbid, optionIndex });
        } else {
          // If it's a new vote, add it to the votes array
          this.votes.push({ dbid: data.dbid, optionIndex });
        }

        // Recalculate vote counts
        const voteCounts = this.questions[this.currentQuestion].options.map(() => 0);
        this.votes.forEach(vote => {
          voteCounts[vote.optionIndex]++;
        });

        // Update the vote counts
        this.questions[this.currentQuestion].options.forEach((option, index) => {
          this.$set(option, 'votes', voteCounts[index]);
        });

        this.updateChartData();
      } else {
        console.warn('ID de opción no encontrado:', data.voteId);
      }
    }
  },
  computed: {
    questions() {
      return this.options.questions;
    },
    filteredOptions() {
      return this.questions[this.currentQuestion].options
        .map((option, index) => ({
          ...option,
          votes: this.votes.filter(vote => vote.optionIndex === index).length,
        }))
        .filter(option => option.votes > 0)
        .sort((a, b) => b.votes - a.votes)
        .slice(0, 5);
    },
    backgroundUrl() {
      return this.options.screenBackground ? 'url(' + encodeURI(this.options.screenBackground.url) + ')' : "url(/assets/trivia/background.png)";
    }
  },
  methods: {
    updateChartData() {
      this.chartData = {
        labels: this.filteredOptions.map(option => option.name),
        datasets: [
          {
            data: this.filteredOptions.map(option => option.votes),
            backgroundColor: this.colors.background,
            borderColor: this.colors.border,
            borderWidth: 2,
            barThickness: 150,
            borderRadius: 5
          }
        ]
      };
    },
    resetVotes() {
      this.votes = [];
    },
    KeyListener(evt) {

      if(evt.key === 'PageDown' || evt.key === 'ArrowRight') {
        
        if (this.currentQuestion < this.questions.length - 1) {
        
          this.currentQuestion++;

          this.$socket.client.emit("roomPlayersMessage", { type: "currentQuestion", question: this.currentQuestion })

          this.resetVotes();
          this.updateChartData();
        }
      }

    },
  },
  mounted() {
    this.colors.border = getComputedStyle(document.documentElement).getPropertyValue('--primary');
    this.colors.background = this.colors.border + 'cc'
    this.updateChartData();

    document.addEventListener("keydown", this.KeyListener); 
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.KeyListener)
  },
};
</script>